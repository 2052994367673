var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "select",
    {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.dynamicSelected,
          expression: "dynamicSelected",
        },
      ],
      staticClass: "form-control custom-select uk-width-1-1",
      on: {
        change: [
          function ($event) {
            var $$selectedVal = Array.prototype.filter
              .call($event.target.options, function (o) {
                return o.selected
              })
              .map(function (o) {
                var val = "_value" in o ? o._value : o.value
                return val
              })
            _vm.dynamicSelected = $event.target.multiple
              ? $$selectedVal
              : $$selectedVal[0]
          },
          _vm.handleChange,
        ],
      },
    },
    [
      _c("option", { attrs: { value: "0" } }, [
        _vm._v(
          _vm._s(_vm.translations.show_all) + " " + _vm._s(_vm.propertyComputed)
        ),
      ]),
      _vm._v(" "),
      _vm._l(this.filter.values, function (item) {
        return _c("option", { domProps: { value: item.id } }, [
          _vm._v("\n    " + _vm._s(item.name) + "\n  "),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }