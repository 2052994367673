<template>
  <select @change="handleChange" v-model="dynamicSelected"  class="form-control custom-select uk-width-1-1">
    <option value="0">{{ translations.show_all }} {{ propertyComputed }}</option>
    <option v-for="item in this.filter.values"  :value="item.id" >
      {{ item.name }}
    </option>
  </select>
</template>

<script>
export default {
  name: "FilterSidebarDynamicFiltersSelect",
  mounted() {},
  data() {
    return {
      localActiveFilters: this.activeFilters,
      dynamicSelected :0,
    };
  },
  methods: {
    handleChange() {
    if(this.dynamicSelected == 0){
      this.filter.values.forEach(fv => {
                this.localActiveFilters[fv.id] = false;
            });
    } else {
        this.filter.values.forEach(fv => {
                this.localActiveFilters[fv.id] = false;
            });
        this.localActiveFilters[this.dynamicSelected] = true;
    }
      this.$emit('input', this.localActiveFilters);
    },
    
      
  },
  computed:{
          propertyComputed() {
            let keysArray = [];
            this.filter.values.forEach(fv => {
              keysArray = Object.keys(this.localActiveFilters);
              if(keysArray.includes(fv.id.toString())) {
                Object.keys(this.localActiveFilters).forEach((key, index) => {
                  if(key == fv.id) {
                    if(Object.values(this.localActiveFilters)[index] == true) {
                       this.dynamicSelected = fv.id;
                    } 
                  }
                })
                // this.dynamicSelected = fv.id;
              }
            });
            },
    },
  props: {
    filter: {
      type: Object | Array,
      required: true,
    },
    activeFilters: {
      type: Object | Array,
      required: true,
    },
    translations: {
      type: Object | Array,
      required: true,
    },
  },
};
</script>

<style scoped>
</style>