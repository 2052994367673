<template>
    <div>
        <div class="row houses-offer mt-4 gy-5" uk-scrollspy="target: > div; cls: uk-animation-slide-bottom-small; delay: 30">
          <product-card
              v-for="product in products"
              :product="product"
              :key="product.id"
              :routes="routes"
              :translations="translations"
          >
          </product-card>
        </div>
        <product-overview-pagination
            v-if="pagination.lastPage > 1"
            :pagination="pagination"
            :translations="translations"
            :routes="routes"
        >
        </product-overview-pagination>
    </div>
</template>

<script>
    import { EventBus } from "../EventBus";
    import ProductOverviewPagination from "./ProductOverviewPagination";
    import * as functions from "../functions.js";

    export default {
        name: "ProductsOverview",
        components: {ProductOverviewPagination},
        mounted(){
          EventBus.$on('RELOAD_PRODUCTS', products => {
            this.products = Object.assign({}, products.products);
            this.pagination = {};
            this.pagination = products.pagination;
          });
        },
        data(){
            return {
              products: this.initProducts.products,
              pagination: this.initProducts.pagination
            }

        },
        methods: {

        },
        props: {
            initProducts: {
                type: Object|Array,
                required: true
            },
            routes: {
                type: Object|Array,
                required: true
            },
            translations: {
                type: Object|Array,
                required: true
            }
        }
    }
</script>

<style scoped>
</style>