var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "formSection" }, [
    _c("div", [
      _c(
        "form",
        { ref: "form", staticClass: "uk-form-stacked form-custom" },
        [
          _vm._l(_vm.messages, function (message) {
            return _c(
              "div",
              {
                class: "uk-alert-" + message.type,
                attrs: { "uk-alert": "", "uk-alert": "" },
              },
              [
                _c("a", {
                  staticClass: "uk-alert-close",
                  attrs: { "uk-close": "" },
                }),
                _vm._v(" "),
                _c("p", [_vm._v(_vm._s(message.message))]),
              ]
            )
          }),
          _vm._v(" "),
          !_vm.hideForm
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "uk-margin", attrs: { "uk-grid": "" } },
                  [
                    _c(
                      "div",
                      { staticClass: "uk-form-group uk-width-1-1" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "uk-form-label",
                            class: { "uk-text-danger": _vm.errors.name },
                            attrs: { for: "name" },
                          },
                          [_vm._v(_vm._s(_vm.translations.name))]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.name,
                              expression: "name",
                            },
                          ],
                          staticClass: "uk-input uk-form-control",
                          class: { "uk-form-danger": _vm.errors.name },
                          attrs: {
                            type: "text",
                            id: "name",
                            name: "name",
                            placeholder: _vm.translations.name,
                          },
                          domProps: { value: _vm.name },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.name = $event.target.value
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm._l(_vm.errors.name, function (error) {
                          return _vm.errors.name
                            ? _c("p", { staticClass: "uk-text-danger mt-1" }, [
                                _vm._v(_vm._s(error)),
                              ])
                            : _vm._e()
                        }),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "uk-form-group uk-width-1-1" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "uk-form-label",
                            class: { "uk-text-danger": _vm.errors.phone },
                            attrs: { for: "phone" },
                          },
                          [_vm._v(_vm._s(_vm.translations.phone))]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.phone,
                              expression: "phone",
                            },
                          ],
                          staticClass: "uk-input uk-form-control",
                          class: { "uk-form-danger": _vm.errors.phone },
                          attrs: {
                            type: "text",
                            id: "phone",
                            name: "phone",
                            placeholder: "Bv. 0032 475 123 123",
                          },
                          domProps: { value: _vm.phone },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.phone = $event.target.value
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm._l(_vm.errors.phone, function (error) {
                          return _vm.errors.phone
                            ? _c("p", { staticClass: "uk-text-danger mt-1" }, [
                                _vm._v(_vm._s(error)),
                              ])
                            : _vm._e()
                        }),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "uk-form-group uk-width-1-1" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "uk-form-label",
                            class: { "uk-text-danger": _vm.errors.email },
                            attrs: { for: "email" },
                          },
                          [_vm._v(_vm._s(_vm.translations.email))]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.email,
                              expression: "email",
                            },
                          ],
                          staticClass: "uk-input uk-form-control",
                          class: { "uk-form-danger": _vm.errors.email },
                          attrs: {
                            type: "email",
                            id: "email",
                            name: "email",
                            placeholder:
                              "Bv. " +
                              _vm.translations.name.toLowerCase() +
                              "@email.com",
                          },
                          domProps: { value: _vm.email },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.email = $event.target.value
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm._l(_vm.errors.email, function (error) {
                          return _vm.errors.email
                            ? _c("p", { staticClass: "uk-text-danger mt-1" }, [
                                _vm._v(_vm._s(error)),
                              ])
                            : _vm._e()
                        }),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "uk-form-group uk-width-1-1" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "uk-form-label",
                            class: { "uk-text-danger": _vm.errors.comments },
                            attrs: { for: "comments" },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.type === "estimate"
                                  ? _vm.translations.extra_info
                                  : _vm.translations.comments
                              )
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.comments,
                              expression: "comments",
                            },
                          ],
                          staticClass: "uk-textarea",
                          class: { "uk-form-danger": _vm.errors.comments },
                          attrs: {
                            name: "comments",
                            id: "comments",
                            cols: "30",
                            rows: "4",
                          },
                          domProps: { value: _vm.comments },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.comments = $event.target.value
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm._l(_vm.errors.comments, function (error) {
                          return _vm.errors.comments
                            ? _c("p", { staticClass: "uk-text-danger mt-1" }, [
                                _vm._v(_vm._s(error)),
                              ])
                            : _vm._e()
                        }),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "uk-form-group uk-width-1-1 form-url",
                        attrs: { id: "url_wrapper" },
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "uk-form-label",
                            attrs: { for: "url" },
                          },
                          [_vm._v("Url *")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.url,
                              expression: "url",
                            },
                          ],
                          staticClass: "uk-input uk-form-control",
                          attrs: {
                            id: "url",
                            name: "url",
                            type: "text",
                            placeholder: "Url",
                          },
                          domProps: { value: _vm.url },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.url = $event.target.value
                            },
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "uk-form-group" },
                      [
                        _c(
                          "label",
                          {
                            staticClass:
                              "uk-form-label uk-form-label-privacy custom-checkbox",
                            class: { "uk-form-danger": _vm.errors.privacy },
                            attrs: { for: "privacy" },
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.privacy,
                                  expression: "privacy",
                                },
                              ],
                              staticClass: "uk-checkbox",
                              attrs: {
                                id: "privacy",
                                name: "privacy",
                                type: "checkbox",
                              },
                              domProps: {
                                checked: Array.isArray(_vm.privacy)
                                  ? _vm._i(_vm.privacy, null) > -1
                                  : _vm.privacy,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.privacy,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.privacy = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.privacy = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.privacy = $$c
                                  }
                                },
                              },
                            }),
                            _vm._v(
                              _vm._s(_vm.translations.i_agree_with) + "  "
                            ),
                            _c("strong", [_vm._v(_vm._s(_vm.company.name))]),
                            _vm._v(" " + _vm._s(_vm.translations.data_privacy)),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: _vm.routes.privacy_page,
                                  target: "_blank",
                                  title: _vm.translations.privacy_policy,
                                },
                              },
                              [_vm._v(_vm._s(_vm.translations.privacy_policy))]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm._l(_vm.errors.privacy, function (error) {
                          return _vm.errors.privacy
                            ? _c("p", { staticClass: "uk-text-danger mt-1" }, [
                                _vm._v(_vm._s(error)),
                              ])
                            : _vm._e()
                        }),
                      ],
                      2
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "uk-button uk-button-primary",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.handleFormSubmit.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _vm._v(_vm._s(_vm.translations.submit)),
                    _c("i", { staticClass: "fa-solid fa-paper-plane ms-2" }),
                  ]
                ),
              ])
            : _vm._e(),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }