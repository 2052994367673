<template>
  <form class="uk-width-1-1">
    <ul class="topfilter d-md-none d-flex flex-column flex-md-row justify-content-between " hidden id="offcanvas-mobile-filters" >
      <!-- ==========[ TYPES FILTER ]========== -->
      <!-- <type-filter
        v-if="categories.length > 0"
        :categories="categories"
        :category="category"
        :translations="translations"
      >
      </type-filter> -->




      <!-- ==========[ PRICE ]========== -->

      

      <filter-sidebar-dynamic-filters
        v-for="filter in dynamicfilters"
        v-model="activeDynamicalFilters"
        :filter="filter"
        :key="filter.id"
        :active-filters="activeDynamicalFilters"
        :translations="translations"
      >
      </filter-sidebar-dynamic-filters>

      <!-- <li class="uk-open">
        <a class="uk-accordion-title" href="#">
          <span>Prijs</span>
          <i class="fa-solid fa-chevron-down"></i>
        </a>

        <div class="uk-accordion-content">
          <div class="sidebar__price">
            <p class="my-0 me-2">&euro;</p>
            <input type="number" v-model="minPrice" step="0.01" name="minp" />
            <p class="my-0 mx-2">tot</p>
            <input type="number" v-model="maxPrice" step="0.01" name="maxp" />
            <button type="submit" @click.prevent="pushQueryString">
              <i class="fa-solid fa-arrow-right"></i>
            </button>
          </div>
        </div>
      </li> -->
      <li class="d-flex flex-column">
        <span>Prijs</span>
        <div class="uk-accordion-content">
            <select class="form-control custom-select" v-model="selectedPrice"  @change="pushQueryString">
               <option :value="0">{{ translations.show_all }}</option>
               <option v-for="option in prices" :value="option">{{ '€' + option[0].toLocaleString() + ' - ' + '€' +option[1].toLocaleString() }}</option>
            </select>
        </div>
      </li>
    </ul>
    <ul class="topfilter d-none d-md-flex flex-column flex-md-row justify-content-between">
      <!-- ==========[ TYPES FILTER ]========== -->
      <!-- <type-filter
        v-if="categories.length > 0"
        :categories="categories"
        :category="category"
        :translations="translations"
      >
      </type-filter> -->




      <!-- ==========[ PRICE ]========== -->

      

      <filter-sidebar-dynamic-filters
        v-for="filter in dynamicfilters"
        v-model="activeDynamicalFilters"
        :filter="filter"
        :key="filter.id"
        :active-filters="activeDynamicalFilters"
        :translations="translations"
      >
      </filter-sidebar-dynamic-filters>

      <!-- <li class="uk-open">
        <a class="uk-accordion-title" href="#">
          <span>Prijs</span>
          <i class="fa-solid fa-chevron-down"></i>
        </a>

        <div class="uk-accordion-content">
          <div class="sidebar__price">
            <p class="my-0 me-2">&euro;</p>
            <input type="number" v-model="minPrice" step="0.01" name="minp" />
            <p class="my-0 mx-2">tot</p>
            <input type="number" v-model="maxPrice" step="0.01" name="maxp" />
            <button type="submit" @click.prevent="pushQueryString">
              <i class="fa-solid fa-arrow-right"></i>
            </button>
          </div>
        </div>
      </li> -->
      <li class="d-flex flex-column">
        <span>Prijs</span>
        <div class="uk-accordion-content">
            <select class="form-control custom-select" v-model="selectedPrice"  @change="pushQueryString">
               <option :value="0">{{ translations.show_all }}</option>
               <option v-for="option in prices" :value="option">{{ '€' + option[0].toLocaleString() + ' - ' + '€' +option[1].toLocaleString() }}</option>
            </select>
        </div>
      </li>
    </ul>
  </form>
</template>

<script>
import * as functions from "../functions.js";
import { EventBus } from "../EventBus";

export default {
  name: "FilterSidebar",
  mounted() {
    EventBus.$on("MOBILE_FILTERS_CHANGED", (prop) => {
      this[prop.changedVar] = {};
      this[prop.changedVar] = prop.newValues;
    });

    EventBus.$on("MOBILE_PRICE_FILTERS_CHANGED", () => {
      this.pushQueryString();
    });
  },
  data() {
    return {
      dynamicfilters: this.initDynamicfilters,
      activeDynamicalFilters: this.initActive(this.getUrlFilter("d", [])),
      minPrice: this.getUrlFilter("minp", null),
      maxPrice: this.getUrlFilter("maxp", null),
      selectedPrice: 0,
      prices:[[0, 100000], [100000, 200000], [200000, 300000], [300000, 400000], [400000, 500000], [500000, 600000], [600000, 700000], [700000, 800000], [800000, 1000000]],
    };
  },
  methods: {
    initActive(initProp) {
      let obj = {};
      initProp.forEach((key) => {
        obj[key] = true;
      });

      return obj;
    },
    pushQueryString() {
      let queryString = this.filtersQuery;

      
        
        if (queryString != "") {
          queryString += ",";
        }
        if(this.selectedPrice != 0) {
          this.minPrice = this.selectedPrice[0];
          if(this.selectedPrice[1]) {
            this.maxPrice = this.selectedPrice[1];
          }
          if (this.minPrice != null && this.minPrice > 0) {
            queryString += "minp:" + this.minPrice + ",";
          }

          if (this.maxPrice != null && this.maxPrice > 0) {
            queryString += "maxp:" + this.maxPrice + ",";
          }
          
        } else {
          
        }
        
      
      

      functions.pushUrlParameter(
        "filters",
        this.encodeQueryString(queryString)
      );
      functions.pushUrlParameter("page", "");

      this.refreshPage();
    },
    encodeQueryString(queryString) {
      if (queryString != "") {
        queryString = encodeURIComponent(window.btoa(queryString.slice(0, -1)));
      }

      return queryString;
    },
    getUrlFilter(which, defaultReturn) {
      let params = functions.getUrlParameters().filters;

      if (params != undefined) {
        if (params.slice(-1) == "#") {
          params = params.slice(0, -1);
        }

        params = decodeURIComponent(params);
        params = window.atob(params);
        params = functions.stringToArray(params);

        let returnSelfParams = ["minp", "maxp"];
        if (returnSelfParams.includes(which)) {
          if (params[which]) {
            return params[which];
          }
        } else {
          if (params[which]) {
            return params[which].split("|");
          }
        }
      }

      return defaultReturn;
    },
    refreshPage() {
      let route =
        this.routes.filtering.reRender +
        functions.buildQueryString(functions.getUrlParameters());

      window.axios.get(route).then((res) => {
        this.dynamicfilters = Object.assign({}, res.data.dynamic_filters);

        EventBus.$emit("RELOAD_PRODUCTS", res.data.products);
      });
    },
  },
  computed: {
    filtersQuery: function () {
      let dynamicFiltersString = "";
      let queryString = "";

      for (const [key, value] of Object.entries(this.activeDynamicalFilters)) {
        if (value) {
          dynamicFiltersString += key + "|";
        }
      }
      if (dynamicFiltersString != "") {
        dynamicFiltersString = "d:" + dynamicFiltersString.slice(0, -1);
        queryString += dynamicFiltersString + ",";
      }

      return queryString.slice(0, -1);
    },
  },
  watch: {
    filtersQuery: function (val, oldVal) {
      this.pushQueryString();
    },
  },
  props: {
    category: {
      type: Object | Array,
      required: true,
    },
    initDynamicfilters: {
      type: Object | Array,
      required: true,
    },
    translations: {
      type: Object | Array,
      required: true,
    },
    routes: {
      type: Object | Array,
      required: true,
    },
  },
};
</script>

<style scoped>
</style>