<template>
  <span class="d-flex flex-sm-row flex-column justify-content-md-end justify-content-between align-items-sm-center">
    <select name="sorting" v-model="sort" @change="handleChange" class="custom-select uk-width-small@m uk-width-small@s">
      <option value="">{{ translations.sort.relevance }}</option>
      <option value="name_asc">{{ translations.sort.name_asc }}</option>
      <option value="name_desc">{{ translations.sort.name_desc }}</option>
      <option value="price_asc">{{ translations.sort.price_asc }}</option>
      <option value="price_desc">{{ translations.sort.price_desc }}</option>
    </select>
  </span>
</template>

<script>
  import * as functions from "../functions.js";
  import { EventBus } from "../EventBus";

  export default {
      name: "ProductOverviewSort",
      mounted() {
      },
      data(){
        return {
          sort: this.initSort()
        }
      },
      methods: {
        initSort(){
          let sort = functions.getUrlParameters().sort;

          if(sort != undefined){
            if(sort.slice(-1) == "#"){
              sort = sort.slice(0, -1);
            }

            sort = decodeURIComponent(sort);
            sort = window.atob(sort);

            return sort;
          }else{
            return '';
          }
        },
        handleChange(){
            functions.pushUrlParameter("sort", encodeURIComponent(window.btoa(this.sort)));

            let route = this.routes.sort + functions.buildQueryString(functions.getUrlParameters());

            window.axios.get(route)
                .then(res => {
                  EventBus.$emit('RELOAD_PRODUCTS', res.data.products);
                });
        }
      },
      computed: {

      },
      watch: {

      },
      props: {
        translations: {
          type: Object|Array,
          required: true
        },
        routes: {
          type: Object|Array,
          required: true
        }
      }
  }
</script>

<style scoped>

</style>