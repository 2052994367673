var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form", { staticClass: "uk-width-1-1" }, [
    _c(
      "ul",
      {
        staticClass:
          "topfilter d-md-none d-flex flex-column flex-md-row justify-content-between ",
        attrs: { hidden: "", id: "offcanvas-mobile-filters" },
      },
      [
        _vm._l(_vm.dynamicfilters, function (filter) {
          return _c("filter-sidebar-dynamic-filters", {
            key: filter.id,
            attrs: {
              filter: filter,
              "active-filters": _vm.activeDynamicalFilters,
              translations: _vm.translations,
            },
            model: {
              value: _vm.activeDynamicalFilters,
              callback: function ($$v) {
                _vm.activeDynamicalFilters = $$v
              },
              expression: "activeDynamicalFilters",
            },
          })
        }),
        _vm._v(" "),
        _c("li", { staticClass: "d-flex flex-column" }, [
          _c("span", [_vm._v("Prijs")]),
          _vm._v(" "),
          _c("div", { staticClass: "uk-accordion-content" }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedPrice,
                    expression: "selectedPrice",
                  },
                ],
                staticClass: "form-control custom-select",
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.selectedPrice = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    _vm.pushQueryString,
                  ],
                },
              },
              [
                _c("option", { domProps: { value: 0 } }, [
                  _vm._v(_vm._s(_vm.translations.show_all)),
                ]),
                _vm._v(" "),
                _vm._l(_vm.prices, function (option) {
                  return _c("option", { domProps: { value: option } }, [
                    _vm._v(
                      _vm._s(
                        "€" +
                          option[0].toLocaleString() +
                          " - " +
                          "€" +
                          option[1].toLocaleString()
                      )
                    ),
                  ])
                }),
              ],
              2
            ),
          ]),
        ]),
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "ul",
      {
        staticClass:
          "topfilter d-none d-md-flex flex-column flex-md-row justify-content-between",
      },
      [
        _vm._l(_vm.dynamicfilters, function (filter) {
          return _c("filter-sidebar-dynamic-filters", {
            key: filter.id,
            attrs: {
              filter: filter,
              "active-filters": _vm.activeDynamicalFilters,
              translations: _vm.translations,
            },
            model: {
              value: _vm.activeDynamicalFilters,
              callback: function ($$v) {
                _vm.activeDynamicalFilters = $$v
              },
              expression: "activeDynamicalFilters",
            },
          })
        }),
        _vm._v(" "),
        _c("li", { staticClass: "d-flex flex-column" }, [
          _c("span", [_vm._v("Prijs")]),
          _vm._v(" "),
          _c("div", { staticClass: "uk-accordion-content" }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedPrice,
                    expression: "selectedPrice",
                  },
                ],
                staticClass: "form-control custom-select",
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.selectedPrice = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    _vm.pushQueryString,
                  ],
                },
              },
              [
                _c("option", { domProps: { value: 0 } }, [
                  _vm._v(_vm._s(_vm.translations.show_all)),
                ]),
                _vm._v(" "),
                _vm._l(_vm.prices, function (option) {
                  return _c("option", { domProps: { value: option } }, [
                    _vm._v(
                      _vm._s(
                        "€" +
                          option[0].toLocaleString() +
                          " - " +
                          "€" +
                          option[1].toLocaleString()
                      )
                    ),
                  ])
                }),
              ],
              2
            ),
          ]),
        ]),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }