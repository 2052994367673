var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "mobile-menu",
      attrs: {
        id: "offcanvas-mobile-filters",
        "uk-offcanvas": "flip: true; overlay: true",
      },
    },
    [
      _c("div", { staticClass: "uk-offcanvas-bar" }, [
        _c("div", { staticClass: "mobile-menu__header" }, [
          _c("img", {
            staticClass: "lazyload",
            attrs: {
              "data-src": _vm.logoUrl,
              width: "20",
              alt: "Logo",
              draggable: "false",
            },
          }),
          _vm._v(" "),
          _vm._m(0),
        ]),
        _vm._v(" "),
        _c("form", { staticClass: "form-custom" }, [
          _c(
            "ul",
            {
              staticClass: "mobile-menu__accordion",
              attrs: { "uk-accordion": "" },
            },
            [
              _vm.categories.length > 0
                ? _c("filter-sidebar-categories", {
                    attrs: {
                      categories: _vm.categories,
                      category: _vm.category,
                      "uk-open": false,
                      translations: _vm.translations,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  { staticClass: "uk-accordion-title", attrs: { href: "#" } },
                  [
                    _c("span", [_vm._v(_vm._s(_vm.translations.price))]),
                    _vm._v(" "),
                    _c("i", { staticClass: "fa-solid fa-chevron-down" }),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "uk-accordion-content" }, [
                  _c("div", { staticClass: "sidebar__price" }, [
                    _c("p", { staticClass: "my-0 me-2" }, [_vm._v("€")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.minPrice,
                          expression: "minPrice",
                        },
                      ],
                      attrs: { type: "number", step: "0.01", name: "minp" },
                      domProps: { value: _vm.minPrice },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.minPrice = $event.target.value
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("p", { staticClass: "my-0 mx-2" }, [
                      _vm._v(_vm._s(_vm.translations.until)),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.maxPrice,
                          expression: "maxPrice",
                        },
                      ],
                      attrs: { type: "number", step: "0.01", name: "maxp" },
                      domProps: { value: _vm.maxPrice },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.maxPrice = $event.target.value
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        attrs: { type: "submit" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.dispatchPriceFilter.apply(
                              null,
                              arguments
                            )
                          },
                        },
                      },
                      [_c("i", { staticClass: "fa-solid fa-arrow-right" })]
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _vm._l(_vm.dynamicfilters, function (filter) {
                return _c("filter-sidebar-dynamic-filters", {
                  key: filter.id,
                  attrs: {
                    filter: filter,
                    "active-filters": _vm.activeDynamicalFilters,
                    "uk-open": false,
                  },
                  model: {
                    value: _vm.activeDynamicalFilters,
                    callback: function ($$v) {
                      _vm.activeDynamicalFilters = $$v
                    },
                    expression: "activeDynamicalFilters",
                  },
                })
              }),
            ],
            2
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mobile-menu__apply" }, [
          _c(
            "button",
            { staticClass: "uk-button uk-button-primary uk-width-1-1" },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.translations.apply_filters) +
                  "\n      "
              ),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      { staticClass: "uk-offcanvas-close", attrs: { type: "button" } },
      [_c("i", { staticClass: "fa-solid fa-xmark" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }