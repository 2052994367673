<template>
  <div id="offcanvas-mobile-filters" class="mobile-menu" uk-offcanvas="flip: true; overlay: true">
    <div class="uk-offcanvas-bar">
      <div class="mobile-menu__header">
        <img class="lazyload" :data-src="logoUrl" width="20" alt="Logo" draggable="false" />

        <button class="uk-offcanvas-close" type="button">
          <i class="fa-solid fa-xmark"></i>
        </button>
      </div>

      <form class="form-custom">
        <ul class="mobile-menu__accordion" uk-accordion>
          <!-- ==========[ SUB CATEGORIES ]========== -->
          <filter-sidebar-categories
              v-if="categories.length > 0"
              :categories="categories"
              :category="category"
              :uk-open="false"
              :translations="translations"
          >
          </filter-sidebar-categories>



          <!-- ==========[ PRICE ]========== -->
          <li>
            <a class="uk-accordion-title" href="#">
              <span>{{ translations.price }}</span>
              <i class="fa-solid fa-chevron-down"></i>
            </a>

            <div class="uk-accordion-content">
              <div class="sidebar__price">
                <p class="my-0 me-2">&euro;</p>
                <input type="number" v-model="minPrice" step="0.01" name="minp">
                <p class="my-0 mx-2">{{ translations.until }}</p>
                <input type="number" v-model="maxPrice" step="0.01" name="maxp">
                <button type="submit" @click.prevent="dispatchPriceFilter"><i class="fa-solid fa-arrow-right"></i></button>
              </div>
            </div>
          </li>


          <filter-sidebar-dynamic-filters
              v-for="filter in dynamicfilters"
              v-model="activeDynamicalFilters"
              :filter="filter"
              :key="filter.id"
              :active-filters="activeDynamicalFilters"
              :uk-open="false"
          >
          </filter-sidebar-dynamic-filters>
        </ul>
      </form>

      <div class="mobile-menu__apply">
        <button class="uk-button uk-button-primary uk-width-1-1">
          {{ translations.apply_filters }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import * as functions from "../functions.js";
  import { EventBus } from "../EventBus";

  export default {
    name: "FilterMobile",
    mounted(){
    },
    data(){
      return {
        dynamicfilters: this.initDynamicfilters,
        activeDynamicalFilters: this.initActive(this.getUrlFilter('d', [])),
        minPrice: this.getUrlFilter('minp', null),
        maxPrice: this.getUrlFilter('maxp', null)
      }
    },
    methods : {
      initActive(initProp) {
        let obj = {};
        initProp.forEach(key => {
          obj[key] = true;
        })

        return obj;
      },
      getUrlFilter(which, defaultReturn) {
        let params = functions.getUrlParameters().filters;

        if(params != undefined){
          if(params.slice(-1) == "#")
          {
            params = params.slice(0, -1);
          }

          params = decodeURIComponent(params);
          params = window.atob(params);
          params = functions.stringToArray(params);

          let returnSelfParams = ["minp", "maxp"];
          if(returnSelfParams.includes(which)){
            if(params[which]){
              return params[which];
            }
          }else{
            if(params[which]){
              return params[which].split('|');
            }
          }
        }

        return defaultReturn;
      },
      dispatchChange(changedVar, newValues){
        EventBus.$emit('MOBILE_FILTERS_CHANGED', {changedVar: changedVar, newValues: newValues});
      },
      dispatchPriceFilter(){
        this.dispatchChange('minPrice', this.minPrice);
        this.dispatchChange('maxPrice', this.maxPrice);
        EventBus.$emit('MOBILE_PRICE_FILTERS_CHANGED');
      }
    },
    computed: {
      logoUrl(){
        return this?.logo ?? '';
      },
    },
    watch: {
      activeDynamicalFilters: function(val, oldVal){
        this.dispatchChange('activeDynamicalFilters', val);
      },
    },
    props: {
      category: {
        type: Object|Array,
        required: true
      },
      categories: {
        type: Object|Array,
        required: true
      },
      initDynamicfilters: {
        type: Object|Array,
        required: true
      },
      translations: {
        type: Object|Array,
        required: true
      },
      logo: {
        type: String,
        required: false
      }
    }
  }
</script>

<style scoped>

</style>