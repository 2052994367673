var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "option",
    {
      attrs: { name: _vm.labelid },
      domProps: { value: _vm.testkey, selected: _vm.checked },
      on: { click: _vm.handleChange },
    },
    [_vm._v("\n    " + _vm._s(_vm.filter.name) + "\n  ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }