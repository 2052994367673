var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", { class: { "uk-open": _vm.ukOpen } }, [
    _c(
      "span",
      { staticClass: "d-flex" },
      [
        _vm.filter.description !== null && _vm.filter.description !== ""
          ? _c("info-circle", {
              attrs: {
                headline: _vm.filter.name,
                body: _vm.filter.description,
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _c("span", [_vm._v(_vm._s(_vm.filter.name))]),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "uk-accordion-content" },
      [
        _c("filter-sidebar-dynamic-filters-select", {
          attrs: {
            filter: _vm.filter,
            "active-filters": _vm.localActiveFilters,
            translations: _vm.translations,
          },
          on: { input: _vm.handleChange },
          model: {
            value: _vm.localActiveFilters,
            callback: function ($$v) {
              _vm.localActiveFilters = $$v
            },
            expression: "localActiveFilters",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }