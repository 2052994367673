var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-sm-6 col-md-4 houses-offer-item" }, [
    _vm.product.status === "SOLD" || _vm.product.status === "RENTED"
      ? _c("div", [
          _c(
            "div",
            {
              staticClass: "house-image lazyload background rounded",
              class: _vm.background,
              staticStyle: { "padding-bottom": "66.4%" },
              style: { backgroundImage: "url(" + _vm.imageUrl + ")" },
            },
            [
              _vm.product.is_new &&
              _vm.product.status != "SOLD" &&
              _vm.product.status != "RENTED"
                ? _c("div", { staticClass: "house-image__tag" }, [
                    _vm._v(_vm._s(_vm.translations.new)),
                  ])
                : _vm.product.status === "SOLD"
                ? _c("div", { staticClass: "house-image__tag" }, [
                    _vm._v(_vm._s(_vm.translations.sold)),
                  ])
                : _vm.product.status === "RENTED"
                ? _c("div", { staticClass: "house-image__tag" }, [
                    _vm._v(_vm._s(_vm.translations.rented)),
                  ])
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "house-data" }, [
            _c("h3", { staticClass: "house-data__title mb-0" }, [
              _vm._v(_vm._s(_vm.product.city)),
            ]),
            _vm._v(" "),
            _vm.product.status === "SOLD"
              ? _c("h4", { staticClass: "house-data__price mt-1" }, [
                  _vm._v(_vm._s(_vm.translations.sold)),
                ])
              : _vm.product.status === "RENTED"
              ? _c("h4", { staticClass: "house-data__price mt-1" }, [
                  _vm._v(_vm._s(_vm.translations.rented)),
                ])
              : _c("h4", { staticClass: "house-data__price mt-1" }, [
                  _vm._v("€ " + _vm._s(_vm.product.formatted.price)),
                ]),
            _vm._v(" "),
            _c("p", { staticClass: "house-data__intro" }, [
              _vm._v(_vm._s(_vm.product.name)),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "house-data__details" }),
          ]),
        ])
      : _c(
          "a",
          {
            staticClass: "house",
            attrs: { href: _vm.product.slug, title: _vm.product.name },
          },
          [
            _c(
              "div",
              {
                staticClass: "house-image lazyload background rounded",
                class: _vm.background,
                staticStyle: { "padding-bottom": "66.4%" },
                style: { backgroundImage: "url(" + _vm.imageUrl + ")" },
              },
              [
                _vm.product.is_new &&
                _vm.product.status != "SOLD" &&
                _vm.product.status != "RENTED" &&
                _vm.product.status != "OPTION_FOR_SALE" &&
                _vm.product.status != "OPTION_FOR_RENT"
                  ? _c("div", { staticClass: "house-image__tag" }, [
                      _vm._v(_vm._s(_vm.translations.new)),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.product.status === "SOLD"
                  ? _c("div", { staticClass: "house-image__tag" }, [
                      _vm._v(_vm._s(_vm.translations.sold)),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.product.status === "OPTION_FOR_SALE" ||
                _vm.product.status === "OPTION_FOR_RENT"
                  ? _c("div", { staticClass: "house-image__tag" }, [
                      _vm._v(_vm._s(_vm.translations.in_option)),
                    ])
                  : _vm._e(),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "house-data" }, [
              _c("h3", { staticClass: "house-data__title mb-0" }, [
                _vm._v(_vm._s(_vm.product.city)),
              ]),
              _vm._v(" "),
              _c("h4", { staticClass: "house-data__price mt-1" }, [
                _vm._v("€ " + _vm._s(_vm.product.formatted.price)),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "house-data__intro" }, [
                _vm._v(_vm._s(_vm.product.name)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "house-data__details" }),
            ]),
          ]
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }