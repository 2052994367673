<template>
  <option :name="labelid" :value="testkey" :selected="checked" @click="handleChange">
      {{ filter.name }}
    </option>
</template>

<script>
export default {
  name: "FilterSidebarDynamicFiltersSelectItem",
  mounted() {},
  data() {
    return {
      localActiveFilters: this.activeFilters,
      checked: this.activeFilters[this.filter.id] || false
    };
  },
  methods: {
    handleChange() {
      this.checked = !this.checked;
      this.filter.values.forEach(fv => {
                this.localActiveFilters[fv.id] = false;
            });
      console.log(this.filter.id);
      this.localActiveFilters[this.filter.id] = this.checked;

      this.$emit('input', this.localActiveFilters);
      // if (val.target.value != "") {
      //    this.localActiveFilters = Object.assign({}, val);
      //           this.$emit('input', this.localActiveFilters);
      //   // this.testarray.push({[val.target.value] : true});
      //   // this.$emit("input",  {[val.target.value] : true});
      // }
      // else {
      //   this.$emit("input", Object.assign({}, { }));
      // }
    },
  },
  watch: {
        activeFilters(val, oldVal) {
            this.checked = val[this.filter.id] || false;
        }
    },
  props: {
    filter: {
            type: Object|Array,
            required: true
        },
        labelid: {
            type: Object|Array,
            required: true
        },
        labelid: {
            type: Object|Array,
            required: true
        },
        activeFilters: {
            type: Object|Array,
            required: true
        },
        filterValues: {
            type: Object|Array,
            required: true
        }
  },
};
</script>

<style scoped>
</style>