<template>
    <div class="col-sm-6 col-md-4 houses-offer-item">
        <div v-if="product.status === 'SOLD' || product.status === 'RENTED'">
            <div class="house-image lazyload background rounded" :class="background" v-bind:style="{ backgroundImage: 'url(' + imageUrl + ')' }" style="padding-bottom: 66.4%;" >
                <div class="house-image__tag" v-if="product.is_new && (product.status != 'SOLD' && product.status != 'RENTED')">{{translations.new}}</div>
                <div class="house-image__tag" v-else-if="product.status === 'SOLD'">{{translations.sold}}</div>
                <div class="house-image__tag" v-else-if="product.status === 'RENTED'">{{translations.rented}}</div>

                <!-- <div class="lazyload" data-bgset="<?= $page->image->size(2400, 2400, ['cropping' => false])->url ?>"></div> -->
                <!-- <div class="lazyload-wrapper" style="padding-bottom: 66.4%;">
                    <img :data-src="imageUrl" alt="temp house" class="lazyload">
                </div> -->
            </div>
            <div class="house-data">
                <h3 class="house-data__title mb-0">{{product.city}}</h3>
                <h4 class="house-data__price mt-1" v-if="product.status === 'SOLD'">{{translations.sold}}</h4>
                <h4 class="house-data__price mt-1" v-else-if="product.status === 'RENTED'">{{translations.rented}}</h4>
                <h4 class="house-data__price mt-1" v-else>€ {{product.formatted.price}}</h4>
                <p class="house-data__intro">{{product.name}}</p>
                <div class="house-data__details"></div>
            </div>
        </div>
        <a :href="product.slug" :title="product.name" v-else class="house">
             <div class="house-image lazyload background rounded" :class="background" v-bind:style="{ backgroundImage: 'url(' + imageUrl + ')' }" style="padding-bottom: 66.4%;" >
                <div class="house-image__tag" v-if="product.is_new && (product.status != 'SOLD' && product.status != 'RENTED') && (product.status != 'OPTION_FOR_SALE' && product.status != 'OPTION_FOR_RENT')">{{translations.new}}</div>
                <div class="house-image__tag" v-if="product.status === 'SOLD'">{{translations.sold}}</div>
                <div class="house-image__tag" v-if="product.status === 'OPTION_FOR_SALE' || product.status ==='OPTION_FOR_RENT'">{{translations.in_option}}</div>

                <!-- <div class="lazyload" data-bgset="<?= $page->image->size(2400, 2400, ['cropping' => false])->url ?>"></div> -->
                <!-- <div class="lazyload-wrapper" style="padding-bottom: 66.4%;">
                    <img :data-src="imageUrl" alt="temp house" class="lazyload">
                </div> -->
            </div>
            <div class="house-data">
                <h3 class="house-data__title mb-0">{{product.city}}</h3>
                <h4 class="house-data__price mt-1">€ {{product.formatted.price}}</h4>
                <p class="house-data__intro">{{product.name}}</p>
                <div class="house-data__details"></div>
            </div>
        </a>
    </div>
</template>

<script>
    import { EventBus } from "../EventBus";

    export default {
        name: "ProductCard",
        data(){
            return {
                isLoading: false,
            }
        },
        methods: {
        },
        computed: {
          imageUrl() {
            return this.product?.image?? '/dist/assets/images/logo_white.png';
          },
          background() {
            if(this.product?.image) {
                return '';
            } else {
                return 'background-nosize';
            }
          },
        },
        props: {
            product: {
                type: Object|Array,
                required: true
            },
            routes: {
              type: Object|Array,
              required: true
            },
            translations: {
              type: Object|Array,
              required: true
            }
        }
    }
</script>

<style scoped>
    
</style>
