<template>
    <li class="mb-2">
        <label class="custom-radio">
            <input class="uk-radio me-2" :name="labelid" :checked="checked" @click="handleChange" type="radio">

            <div class="d-flex align-items-baseline">
                <p class="my-0">{{ filter.name }}</p>
            </div>
        </label>
    </li>
</template>

<script>
export default {
    name: 'FilterSidebarDynamicFiltersRadiosItem',
    mounted() {

    },
    data() {
        return {
            localActiveFilters: this.activeFilters,
            checked: this.activeFilters[this.filter.id] || false
        }
    },
    methods: {
        handleChange() {
            this.checked = !this.checked;

            this.filterValues.forEach(fv => {
                this.localActiveFilters[fv.id] = false;
            });

            this.localActiveFilters[this.filter.id] = this.checked;

            this.$emit('input', this.localActiveFilters);
        }
    },
    watch: {
        activeFilters(val, oldVal) {
            this.checked = val[this.filter.id] || false;
        }
    },
    props: {
        filter: {
            type: Object|Array,
            required: true
        },
        labelid: {
            type: Object|Array,
            required: true
        },
        activeFilters: {
            type: Object|Array,
            required: true
        },
        filterValues: {
            type: Object|Array,
            required: true
        }
    }
}
</script>

<style scoped>
</style>