import { render, staticRenderFns } from "./AddressCard.vue?vue&type=template&id=fb6b4a04&scoped=true"
import script from "./AddressCard.vue?vue&type=script&lang=js"
export * from "./AddressCard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fb6b4a04",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('fb6b4a04')) {
      api.createRecord('fb6b4a04', component.options)
    } else {
      api.reload('fb6b4a04', component.options)
    }
    module.hot.accept("./AddressCard.vue?vue&type=template&id=fb6b4a04&scoped=true", function () {
      api.rerender('fb6b4a04', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/assets/js/Components/AddressCard.vue"
export default component.exports