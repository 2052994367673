<template>
  <li v-bind:class="{ 'uk-open': ukOpen }">
    <span class="d-flex">
      <info-circle
        v-if="filter.description !== null && filter.description !== ''"
        :headline="filter.name"
        :body="filter.description"
      ></info-circle>
      <span>{{ filter.name }}</span>
    </span>
    <div class="uk-accordion-content">
      <filter-sidebar-dynamic-filters-select
        v-model="localActiveFilters"
        :filter="filter"
        :active-filters="localActiveFilters"
        @input="handleChange"
        :translations="translations"
      >
      </filter-sidebar-dynamic-filters-select>
    </div>

    <!-- <div class="uk-accordion-content">
      <ul class="sidebar__nav">
        <filter-sidebar-dynamic-filters-checks
          v-if="filter.type == 'check'"
          v-model="localActiveFilters"
          :filter="filter"
          :active-filters="localActiveFilters"
          @input="handleChange"
        >
        </filter-sidebar-dynamic-filters-checks>
        <filter-sidebar-dynamic-filters-radios
          v-if="filter.type == 'radio'"
          v-model="localActiveFilters"
          :filter="filter"
          :active-filters="localActiveFilters"
          @input="handleChange"
        >
        </filter-sidebar-dynamic-filters-radios>
      </ul>
    </div> -->
  </li>
</template>

<script>
import InfoCircle from "./InfoCircle";
export default {
  name: "FilterSidebarDynamicFilters",
  components: { InfoCircle },
  mounted() {},
  data() {
    return {
      localActiveFilters: this.activeFilters,
      selectInput: 0,
    };
  },
  methods: {
    handleChange(val) {
      this.localActiveFilters = Object.assign({}, val);

      this.$emit("input", this.localActiveFilters);
      // this.$on("selectinput", (data) => {
      //   this.selectInput = data;
      //   console.log(data);
      // })
    },
  },
  computed: {
  },
  props: {
    filter: {
      type: Object | Array,
      required: true,
    },
    activeFilters: {
      type: Object | Array,
      required: true,
    },
    ukOpen: {
      type: Boolean,
      required: false,
      default: true,
    },
    translations: {
      type: Object | Array,
      required: true,
    },
  },
};
</script>

<style scoped>
</style>